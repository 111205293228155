// const app_api = "http://127.0.0.1:5000"
// //  const app_api = "http://192.168.15.205:5000"
// //  const app_api = "http://192.168.1.192:5000"
// //  const app_api = "http://192.168.1.3:5000"
// export default app_api;

const app_api = () => {
    return window.REACT_APP_API_URL; 
  };
  
  export default app_api;