export default {
    // lang
    "vi": "Tiếng Việt",
    "en": "English",
    "signin": "Đăng nhập",
    "account": "Tên đăng nhập",
    // "password": "Mật khẩu",
    "forgot password": "Quên mật khẩu ?",
    "remember me": "Ghi nhớ đăng nhập",
    "online": "Trực tuyến",
    "general": "Tổng quan",
    "search": "Tìm kiếm",
    "result": "Kết quả",
    // "results": "kết quả",
    "my profile": "Hồ sơ của tôi",
    "settings": "Cấu hình",
    "help": "Trợ giúp",
    "signout": "Đăng xuất",
    "dashboard": "Bảng điều khiển",
    "home": "Trang chủ",
    "diagram": "Biểu đồ",
    "user info": "Xin chào",
    "projects manager": "Quản lý dự án",
    "project list": "Danh sách dự án",
    "accounts manager": "Quản lý tài khoản",
    "accounts list": "Danh sách tài khoản",
    "profile": "Thông tin cá nhân",
    "profile user": "Thông tin tài khoản",
    "profile user update": "Cập nhật thông tin cá nhân",
    "min ago": "phút trước",
    "report": "Báo cáo",
    "tasks": "Yêu cầu",
    "task": "Yêu cầu",
    "projects worked": "Dự án đã tham gia",
    "statistic": "Thống kê",
    "workflow": "Quy trình",
    "about us": "Giới thiệu",
    "contacts": "Liên hệ",
    "404": "KHÔNG TÌM THẤY",
    "settings.title": "Cấu hình",
    "settings.desc": "Cấu hình chung cho ứng dụng",
    "settings.languages": "Ngôn ngữ",
    "settings.color": "Màu sắc",
    "settings.color button": "Thay đổi",
    //description
    "description": "Mô tả",
    "data management": "Quản lý dữ liệu",

    "project.info": "Thông tin dự án",
    "project.deploy": "Thông tin website triển khai",
    //Project
    "projects": "Dự án",
    "projects.title": "Quản lý dự án",
    "project_detail.title": "Chi tiết dự án",
    //status
    "initialization": "Khởi tạo",
    "implement": "Thực hiện",
    "deploy": "Triển khai",
    "complete": "Hoàn thành",
    "pause": "Tạm dừng",
    //List project
    "projectcode": "Mã dự án",
    "projectmanager": "Quản lý dự án",
    "projectprocess": "Tiến độ dự án",
    "projectmember": "Thành viên",
    "projectempty": "Chưa có thành viên",
    "createby": "Tạo bởi",
    "by": " bởi ",
    //add project
    "addproject": "Thêm mới dự án",

    "projectname": "Tên dự án",
    "p.projectname": "Nhập tên dự án",
    // "projectcode": "Mã dự án",
    "p.projectcode": "Nhập mã dự án",
    "projectstatus": "Trạng thái",
    "p.projectstatus": "Chọn trạng thái",
    "projectrole": "Người quản lý",
    "p.projectrole": "Chọn người quản lý",
    "projectdescripton": "Mô tả dự án",
    "p.projectdescripton": "Nhập mô tả dự án",
    // "projectmember": "Thành viên dự án",
    //update project
    "updateproject": "Cập nhật dự án",

    //Task  
    "tasklist": "Danh sách các yêu cầu",
    "detailtask": "Chi tiết yêu cầu",
    "addtask": "Thêm yêu cầu",
    "taskname": "Tên yêu cầu",
    "p.taskname": "Nhập tên yều cầu",
    "taskstatus": "Trạng thái",
    "task_priority": "Mức độ ưu tiên",
    "taskmember": "Thành viên",
    "p.description": "Mô tả yêu cầu",
    "updatestatus": "Cập nhật trạng thái",
    "viewdetail": "Xem chi tiết",
    "delete": "Xóa",
    "edit": "Chỉnh sửa",
    "edittask": "Chỉnh sửa yêu cầu",


    //Managers
    "editmanager": "Chỉnh sửa quản lý dự án",
    //Members
    "members": "Thành viên",
    //button project
    "buttondetail": "Xem chi tiết",
    // error project
    "error.project_name": "Vui lòng nhập tên dự án",
    "error.project_status": "Vui lòng chọn trạng thái dự án",

    //Version
    "version": "Phiên bản",
    "versionupdate": "Cập nhật phiên bản",
    "versionname": "Tên phiên bản",
    "p.versionname": "Nhập tên phiên bản",
    "p.versiondescription": "Nhập mô tả",
    //Task
    // "task": "Yêu cầu",
    "duty": "Chức vụ",
    "confirm": "Xác nhận",
    //User
    "users.title": "Quản lý người dùng",
    //Group user
    "administrator": " Quản trị viên",
    "uprojectmanager": " Vận hành",
    "normal": " Người dùng",
    "monitor": "Người theo dõi dự án",
    //list user
    // "username": "Tên đăng nhập",
    // "permission": "Quyền",
    "time": "Thời gian",
    // modal add user
    "btn.create": "Thêm mới",
    "btn.close": "Đóng",
    "adduser.title": "Thêm người dùng",
    "profile.title": "Cập nhật hồ sơ",
    "fullname": "Họ tên",
    "p.fullname": "Nhập đầy đủ họ tên",
    "username": "Tên đăng nhập",
    "p.username": "Nhập tên đăng nhập",
    "password": "Mật khẩu",
    "p.password": "Nhập mật khẩu",
    "re-password": "Nhập lại mật khẩu",
    "p.re-password": "Nhập lại mật khẩu",
    "email": "Email",
    "p.email": "Nhập tài khoản email",
    "phone": "Số điện thoại",
    "p.phone": "Nhập số điện thoại",
    "permission": "Quyền",
    "p.permission": "Chọn quyền",
    "address": "Địa chỉ",
    "p.address": "Nhập địa chỉ",
    "note": "Ghi chú",
    "p.note": "Nhập ghi chú",

    //Error user
    "error.fullname": "Vui lòng nhập đầy đủ họ tên",
    "error.username": "Vui lòng nhập tên đăng nhập",
    "error.password": "Vui lòng nhập mật khẩu",
    "error.confirmpassowrd": "Mật khẩu không khớp",
    "error.email": "Vui lòng nhập email",
    "error.validemail": "Email không hợp lệ",
    "error.phone": "Vui lòng nhập số điện thoại",
    "error.vaildphone": "Số điện thoại không hợp lệ",
    "error.permission": "Vui lòng chọn quyền",
    "error.address": "Vui lòng nhập địa chỉ",
    // button modal edit user
    "btn.update": "Lưu lại",
    "edituser.title": "Chỉnh sửa người dùng",

    // logs
    "log.information": "Thông tin",
    "log.warning": "Cảnh báo",
    "log.error": "Lỗi",
    "log.title": "Lịch sử",
    "log.statis": "Thống kê",
    "log.type": "Loại",
    "log.selecttype": "Chọn loại",
    "log.daystart": "Ngày bắt đầu",
    "log.dayend": "Ngày kết thúc",
    "log.listlog": "Danh sách lịch sử",
    "log.no": "STT",
    "log.id": "ID",
    "log.listtitle": "Tiêu đề",
    "log.dayupdate": "Ngày cập nhật",
    "log.action": "Thao tác",
    "btn.ok": "Lọc",
    "btn.clear": "Xóa",
    "btn.viewdetail": "Xem chi tiết",
    "detaillog": "Chi tiết lịch sử",
    "log.create_user": "Người thực hiện",
    "log.create_at": "Ngày tạo",

    //page
    "show": "Hiển thị",
    "of": "của",
    "results": "kết quả",
    "supervisor": "Giám sát",
    "deployers": "Triển khai",
    "btn.cancel": "Hủy",
    "btn.addfield": "Thêm mới trường",
    "export": "Xuất",
    "export-to-excel": "Xuất sang Excel",
    "activation": "Kích hoạt bản quyền",
    "success": "Thành công",
    "success.content": "Import dữ liệu thành công",
    "faild": "Thất bại",
    "faild.content": "Import dữ liệu thất bại",
    "format": "Định dạng tệp không hợp lệ",
    "error": "Lỗi",
    "faild.format": " Không đúng định dạng, vui lòng chọn lại!",
    "select file": "Chọn tệp",
    "file name": "Tên tệp",
    "type file": "Dạng tệp",
    "create account": "Tạo tài khoản",
    "manage data": "Quản lý dữ liệu",
    "create": "Tạo mới",
    "MAC": "Địa chỉ MAC máy chủ",
    "active now": "Kích hoạt ngay",
    "select fields": "Chọn trường",
    "select statistic fields ": "Chọn trường thống kê",
    "preview data": "Bản xem trước dữ liệu Excel",
    "export.content.error": "Vui lòng chọn ít nhất một trường trước khi xuất.",
    "success.add": "Thêm thành công",
    "fail.add": "Thêm thất bại",
    "success.update": "Cập nhật thành công",
    "fail.update": "Cập nhật thất bại",
    // "confirm": "Xác nhận xóa",
    "confirm.content": "Bạn có chắc chắn muốn xóa trường này?",
    "btn.delete": "Xóa",
    "btn.cencel": "Hủy",
    "success.delete": "Xoá thành công",
    "fail.delete": "Xoá thất bại",
    "fail.null": "Có một số trường không được bỏ trống",
    "fail.active": "Sản phẩm chưa được kích hoạt",
    "preview.content": "Vui lòng chọn ít nhất một trường để xem dữ liệu mẫu",
    "site map": "Sơ đồ website",
    "not found user": "Chưa có người dùng",
    "not found data": "Chưa có dữ liệu",
    "not found": "Không tìm thấy",
    "activate.key": "Khóa kích hoạt",
    "profile.empty": "Vui lòng điền đầy đủ thông tin",
    // "create": "Thêm mới",
    "update": "Cập nhật",
    "not found config": "Không tìm thấy cấu hình Database và Api",
    "not found update": "Không tìm thấy tính năng cập nhật",
    "api": " Cấu hình Api",
    "database": " Cấu hình Database",
    "error.email_invalidl": "Email không hợp lệ",
    "error.phone_invalid": "Số điện thoại không hợp lệ",
    "choose": "Chọn",
    "select export type": "Chọn loại xuất",
    // "import data": "Nhập dữ liệu",
    "export sample data": "Xuất dữ liệu mẫu",
    "selected file": "File đã chọn",
    "size": "Kích thước",
    "type": "Loại",
    "check file": "Chỉ chấp nhận định dạng tệp .csv, .xlsx, và .xls",
    "loading": "Đang tải...",
    "searching": "Đang tìm kiếm...",
    "import": "Nhập",
    "error.import.primarykey": "Lỗi khóa chính",
    "error.import.duplicate": "Lỗi trùng lặp",
    "error.import.foreign": "Lỗi khóa ngoại",
    "selectall": "Chọn tất cả",
    "imported": "Đang thực hiện",
    "error.format": "File import không đúng định dạng",
    "import.complete": "Import hoàn thành",
    "import.text": "Dữ liệu đã được nhập thành công",
    "total.line": "Tổng số dòng",
    "total.imported": "Số dòng đã import",
    "total.error": "Số dòng lỗi",
    "total.time": "Thời gian",
    "minute": "Phút",
    "back": "Quay lại",
    "copied": "Đã sao chép!",
    "Number of rows exceeds the allowable limit": "Số hàng vượt quá giới hạn cho phép",
    "File size exceeds the allowable limit": "Kích thước tệp vượt quá giới hạn cho phép (Tối đa 120MB)",
    "export_excel_csv": "Xuất Excel/CSV",
    "export data example": "Xuất file mẫu",
    "import data": "Import dữ liệu",
    "value": "Giá trị",
    "criteria": "Tiêu chí",
    "importing": "Đang nhập dữ liệu",
    "privileges manager": "Quản lý quyền",
    "avatar": "Ảnh đại diện",
    "table name": "Tên bảng",
    "privileges": "Phân quyền tài khoản",
    "privileges username": "Tài khoản",
    "read": "Xem",
    "write": "Thêm",
    "modify": "Sửa",
    "purge": "Xóa",
    "erorr pk fk": "Lỗi khóa chính và khóa ngoại",
    "erorr fk": "Lỗi khóa ngoại",
    "erorr pk": "Lỗi khóa chính",
    "char error": "Vượt quá số lượng kí tự cho phép",
    "signout account": "Tài khoản đã được đăng nhập từ một thiết bị khác",
    "notification": "Thông báo",
    "january": 'Tháng 1',
    "february": 'Tháng 2',
    "march": 'Tháng 3',
    "april": 'Tháng 4',
    "may": 'Tháng 5',
    "june": 'Tháng 6',
    "july": 'Tháng 7',
    "august": 'Tháng 8',
    "september": 'Tháng 9',
    "october": 'Tháng 10',
    "november": 'Tháng 11',
    "december": 'Tháng 12',
    "month": "Tháng",
    "year": "Năm",
    "no data": "Không có dữ liệu",
    "next": "Tiếp tục",
    "error.file": "Vui lòng chọn một tệp .txt!",
    "correct format": "Nội dung tệp không đúng định dạng!",
    "back": "Quay về",
    "create key": "Tạo khóa",
    "success create key": "Tạo khóa kích hoạt thành công",
    "change password": "Thay đổi mật khẩu",
    "p.new password": "Nhập mật khẩu mới",
    "new password": "Mật khẩu mới",
    "p.old password": "Nhập mật khẩu cũ",
    "old password": "Mật khẩu cũ",
    "p.re password": "Nhập lại mật khẩu mới",
    "re password": "Nhập lại mật khẩu",
    "change": "Thay đổi",
    "error.input": "Trường này không được để trống",
    "duPassword": "Vui lòng không sử dụng lại mật khẩu cũ",
    "validPassword": "Mật khẩu nhập lại không khớp",
    "success.password": "Mật khẩu của bạn đã được cập nhật. Xin hãy đăng nhập lại để tiếp tục sử dụng dịch vụ với thông tin bảo mật mới ",
    "faild.password": "Mật khẩu không đúng",
    "update.config": "Cập nhật thông tin",
    "enter serialnumber": "Nhập Serial Number",
    "export to file": "Xuất file",
    "step": "Bước",
    "upload file": "Tải File",
    "get data": "Nhận Dữ Liệu",
    "get result": "Lấy Kết Quả",
    "note not share": "Lưu ý: Không chia sẻ khóa này cho bất kỳ ai không được ủy quyền.",
    "create key success": "Tạo khóa kích hoạt thành công!",
    "error.serial": "Chỉ được nhập ký tự từ A-Z và số từ 0-9",
    "no info": "Thông tin chưa được cập nhật",
    "faild key success": "Tạo khóa kích hoạt thất bại!",
    "total quantity": "Tổng số lượng năm",
    "license create": "License đã tạo năm",
    "error.number": "Tối thiểu 8 và tối đa 15 kí tự",
    "export file": "Xuất file",
    "wrong account": "Tài khoản hoặc mật khẩu không chính xác.",
    "wrong info": "Vui lòng nhập cả tên người dùng và mật khẩu.",
    "failed.password": "Thay đổi mật khẩu thất bại",
    "wrong password": "Mật khẩu cũ không chính xác",
    "just now": "Vừa xong",
    "mins ago": "phút trước",
    "secs ago": "giây trước",
    "hours ago": "giờ trước",
    "days ago": "ngày trước",
    "months ago": "tháng trước",
    "years ago": "năm trước",
    "success create": "Tạo thành công",
    "faild create": "Tạo thất bại",
    "post your case": "THÊM TRƯỜNG HỢP CỦA BẠN",
    "sorted by": "Sắp xếp theo",
    "today": "Hôm nay",
    "newest": "Mới nhất",
    "oldest": "Cũ nhất",
    "latest support on": "Hỗ trợ mới nhất ",
    "Posted on": "Đã đăng vào",
    "DISCUSSION": "THẢO LUẬN",
    "SUPPORT QUALITY": "CHẤT LƯỢNG HỖ TRỢ",
    "total": "Tổng số",
    "case(s)": "trường hợp",
    // "ISSUE DESCRIPTION": "MÔ TẢ VẤN ĐỀ",
    "ISSUE DESCRIPTION": "Mô tả vấn đề",
    // "SUGGESTED SOLUTION": "GIẢI PHÁP ĐỀ XUẤT",
    "SUGGESTED SOLUTION": "Giải pháp đề xuất",
    "PRODUCT INFORMATION": "Thông tin sản phẩm",
    "ADD PRODUCT INFORMATION": "Thêm thông tin sản phẩm",

    "Type a message": "Nhập tin nhắn ...",
    "You have not rated the quality of support": "Bạn chưa đánh giá chất lượng của dịch vụ hỗ trợ",
    "rate now": "Đánh giá ngay",
    "APPRICIATE THE SERVICE QUALITY": "ĐÁNH GIÁ CHẤT LƯỢNG DỊCH VỤ",
    "Let us know how you feel": "Hãy cho chúng tôi biết cảm nhận của bạn",
    "Last updated by": "Cập nhật lần cuối bởi",
    "Submit Review": "Gửi đánh giá",
    "Case title, case description, or anything": "Tiêu đề trường hợp, mô tả trường hợp",
    "Show more": "Xem thêm",
    "send message": "Gửi tin nhắn",
    "attachment": "Tập tin đính kèm",
    "new case": "TRƯỜNG HỢP MỚI",
    "case title": "Tiêu đề",
    "case type": "Phân loại",
    "product name": "Tên sản phẩm",
    "CHOOSE FILE": "Chọn tệp",
    "submit case": "Tạo case",
    "Click to change image": "Nhấn để thay đổi hình ảnh",
    "Case Update": "Cập nhật trường hợp",
    "Enter case title": "Nhập tiêu đề",
    "Enter product name": "Nhập tên sản phẩm",
    "p.issue": "Nhập mô tả vấn đề (Tối đa 2000 kí tự)",
    "No attachment": "Không có tệp đính kèm",
    "Choose Image": "Chọn ảnh",
    "no image case": "Không có hình ảnh",
    "No data avasilable": "Không có dữ liệu",
    "success.rate": "Gửi đánh giá thành công",
    "File is too large": "Tổng dung lượng file vượt quá 20MB",
    "controller error": "Lỗi Controller:",
    "printhead error": "Lỗi Printhead ",
    "printer error": "Lỗi Printer:",
    "ERROR0": "Không có lỗi",
    "ERROR1": "Không tìm thấy thông tin thiết bị",
    "ERROR2": "UUID không hợp lệ",
    "ERROR3": "Dữ liệu rỗng",
    "Unknown error": "Lỗi không xác định",
    "List of Errors": "Danh sách các lỗi",
    "Please check the activation file": "Vui lòng kiểm tra tập tin kích hoạt",
    "Click to preview": "Nhấn để xem trước",
    "delete image": "Xóa ảnh",
    "Drag and drop images here": "Kéo và thả (Image, Video, Pdf, Excel) vào đây",
    "Only image, video, PDF, and Excel files are accepted": "Chỉ chấp nhận các tệp hình ảnh, video, Pdf và Excel",
    "Please upload an image file.": "Vui lòng tải lên một tệp hình ảnh.",
    "Notification": "Thông báo",
    "expired": "Phiên hết hạn. Vui lòng đăng nhập lại để tiếp tục.",
    "withdraw": "Thông tin chỉ có thể được cập nhật trong vòng 24 giờ kể từ khi gửi.",
    "Drag and drop images here and click": "Kéo thả ảnh vào đây hoặc bấm để chọn ảnh",
    "case image": "Hình ảnh",


    "Warranty Claim": "Yêu cầu bảo hành",
    "Case Suggest": "Đề xuất",
    "Possible Feature": "Tính năng đề xuất",
    "maximum": "Tối đa",

    "add row": "Thêm hàng",
    "import file": "Nhập tệp tin",
    "sample file": "Tệp tin mẫu",
    "Timeout expired": "Hết thời gian chờ: Pool kết nối đầy hoặc vượt quá số lượng tối đa",
    "format-excel-sample": "Cấu trúc tệp tin không khớp với mẫu dự kiến.",
    "issue part": "Phần có vấn đề",
    "hardwarecategory": "Danh sách hardware",
    "case no": "Số Case",
    "case by": "Xử lý bởi",
    "partner name": "Đại lý",
    "company name": "Tên Công Ty",
    "case status": "Trạng thái xử lý",
    "date occur": "Ngày xảy ra",
    "issue classifi": "Phân loại lỗi",
    "trouble category": "Hướng khắc phục",
    "end user": "Người dùng cuối",
    "case serial": "Case Serial",
    "factory info": "Thông tin nhà máy",
    "new printer lot": "Bảng lot máy in mới",
    "new replaced item": "Các mặt hàng mới được thay thế",
    "origional lot": "Lot gốc",
    "returned item": "Các mặt hàng được trả lại",
    "shipping info": "Thông tin vận chuyển",
    "component": "Linh kiện thay thế",
    "add component": "Thêm linh kiện",
    "advance info": "Thông tin nâng cao"
    
}